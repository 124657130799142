// Variables
@import "modules/variables";

.home-search__container {
   overflow: auto;
}

.home-search__field {
   width: 33.33333%;
   float: left;
   @media @tablet {
      width: 100%;
      margin-bottom: 10px;
   }
}

.select-container {
	&:after {
		display: none;
	}
	select {
		padding: 7px 10px;
	}
}

.job-entry__button {
   display: inline-block;
   margin-right: 10px;
}
